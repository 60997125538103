<template>
<v-container fluid class="pa-0 hover-card">
    <v-sheet tile class="hover-card-top-left" />
    <v-sheet tile class="hover-card-bottom-right" />
    <v-sheet tile class="hover-card-background" />
    <v-container fluid class="pa-0 hover-card-content">
        <slot name="content" />
    </v-container>
</v-container>
</template>

<script>
export default {
    name: "HoverCard"
}
</script>

<style lang="scss">
.hover-card {
    position: relative;
    .container .v-card {
        border-radius: 0px;
    }
}

.hover-card-background, .hover-card-top-left, .hover-card-bottom-right {
    position: absolute;
    transition: all 0.3s ease-in-out;
    background-image: linear-gradient($secondary_color, $secondary_color);
    opacity: 0;
}

.hover-card:hover {
    .hover-card-background {
        opacity: 1;
    }
    .hover-card-top-left {
        opacity: 1;
        top: 0px;
        transform: rotate(-45deg);
    }
    .hover-card-bottom-right {
        opacity: 1;
        right: 0px;
        transform: rotate(45deg);
    }
    .hover-card-content {
        transform: translate(5px, -5px);
    }
    .v-image__image--cover {
        transform: scale(1.15);
        backface-visibility: hidden;
    }
}

.hover-card-background {
    width: 100%;
    height: 100%;
}

.hover-card-top-left {
    transform-origin: top left;
    position: absolute;
    top: 5px;
    left: 0px;
    width: 7px;
    height: 7px;
    transform: rotate(-45deg);
}

.hover-card-bottom-right {
    transform-origin: bottom right;
    position: absolute;
    bottom: 0px;
    right: 5px;
    width: 7px;
    height: 7px;
    transform: rotate(45deg);
}

.hover-card-content, .v-image__image--cover {
    backface-visibility: hidden;
    transition: all 0.3s ease-in-out;
}
</style>
